<template>
  <b-row>
    <b-col cols="12">
      <essential-informations-form
        container-class="mt-1 mb-2"
        :subscription-plan="subscriptionPlan"
        :validation="validation"
        :is-disabled="isDisabled"
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/SubscriptionPlan.json'),
    },
    subscriptionPlan: {
      type: Object,
      default: () => {
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    EssentialInformationsForm: () => import('@form/subscriptions/new/subscription-informations/EssentialInformationsForm')
  }
}
</script>
<style scoped>

</style>
